import React from "react"

import SectionHeader from "./section-header"
import appStoreButton from "../images/app-store-button.png"
import {appStoreUrl} from "./constants"

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Get the iOS app"
    />
    <a href={appStoreUrl}>
    <img src={appStoreButton} style={{maxHeight: 60}}/>
    </a>
  </div>
)

export default CallToAction
